import { configureStore, isImmutableDefault } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import { rootSaga } from "./rootSaga";
import { rootReducer } from "./rootReducer";

const isImmutable = (value) => isImmutableDefault(value) || value instanceof File;

const sagaMiddleware = createSagaMiddleware();

export const getStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: {
                isImmutable: isImmutable,
            }
        }).concat(sagaMiddleware),
    devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
});

sagaMiddleware.run(rootSaga);