

import { put } from "redux-saga/effects";
import { blogOpened } from "../actions";
import AxiosInstance from "../AxisoInstance";

export function* openBlog({payload}) {
    try {
        const blog = yield getBlog(payload);
        yield put(blogOpened(blog));
    } catch(e) {
        console.error(e);
    }
}

const getBlog = async(id) => {
    const response = (await AxiosInstance.get(`blogs/${id}`));
    return response.data;
}