import * as React from 'react';
import { Body } from '../utils/body';
import { SectionHeader } from '../utils/section-header';
import { Contact } from '../utils/contact';

export const ContactPage = () => {
    return (
        <Body title={'Contact - Creative Icon'}>
            <SectionHeader section={'Contact'} />
            <Contact />
        </Body>
    )
}