import moment from 'moment';
import * as React from 'react';
import { Icon } from 'semantic-ui-react';

import './styles/footer.css';


export const Footer = () => {
    return (
        <footer class="footer-section">
        <div class="container">
            <div class="footer-cta pt-5 pb-5">
                <div class="row">
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fas fa-map-marker-alt"></i>
                            <div class="cta-text">
                                <h4>Find us</h4>
                                <span>126/A, Raja Rammohan Sarani, College Street, Kolkata-700009, West Bengal, India</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <Icon className='far' name='phone' size='big' /> 
                            <div class="cta-text">
                                <h4>Call us</h4>
                                <span>+91 9836492808</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <Icon className='far' name='mail' size='big' />
                            <div class="cta-text">
                                <h4>Mail us</h4>
                                <span>info@creativeicon.co.in</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-content pt-5 pb-5">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 mb-50">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <a href="/"><img height={80} src={`${process.env.PUBLIC_URL + '/images/logo.png'}`} class="img-fluid" alt="logo" /></a>
                            </div>
                            <div class="footer-text">
                                <p>Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed do eiusmod tempor incididuntut consec tetur adipisicing
                                elit,Lorem ipsum dolor sit amet.</p>
                            </div>
                            <div class="footer-social-icon">
                                <span>Follow us</span>
                                <a href="#"><Icon className='fab' name='facebook' size='big' /></a>
                                <a href="#"><Icon className='fab' name='instagram' size='big' /></a>
                                <a href="#"><Icon className='fab' name='youtube' size='big' /></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                        
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                        <div class="copyright-text">
                            <p>Copyright &copy; {moment().year()}, All Right Reserved <a href="/">Creative Icon</a></p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                        <div class="footer-menu">
                            {/* <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">Terms</a></li>
                                <li><a href="#">Privacy</a></li>
                                <li><a href="#">Policy</a></li>
                                <li><a href="#">Contact</a></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}