import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { HeaderText } from './header-text';
import { Panel1 } from './panel1';

import './styles/contacts.css';

import 'leaflet/dist/leaflet.css';
import { FormComponent } from './form';
import { useDispatch, useSelector } from 'react-redux';
import { contactResponseRecieved, contactSend, loginResponseRecieved } from '../../actions';
import { reset } from 'redux-form';
import { toast } from 'react-toastify';
import { getContactResponse } from '../../selectors';

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
  
L.Marker.prototype.options.icon = DefaultIcon;

const positionArray = [{
    position: ['22.570650','88.366090'],
    office: 'Creative Icon'
}]

export const Contact = ({}) => {
    const dispatch = useDispatch();
    const response = useSelector(getContactResponse);
    const onSubmit = (payload) => {
        dispatch(contactSend(payload));
    }
    React.useEffect(()=>{
        if(response) {
            toast(response.type==='SUCCESS' ? 'Saved':'Error Occured');
            if(response.type==='SUCCESS') {
                dispatch(reset('contact'));
            }
            dispatch(contactResponseRecieved(undefined));
        }
    },[response])
    React.useEffect(()=>{
        return (()=>{
            dispatch(loginResponseRecieved(undefined));
        })
    },[])
    return (
        <>
        <div class="container-contact">
      <span class="big-circle"></span>
      <div class="form">
        <div class="contact-info">
          <h3 class="title">Let's get in touch</h3>
          <p class="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
            dolorum adipisci recusandae praesentium dicta!
          </p>

          <div class="info">
            <div class="information">
              <Icon className='fas' name='map' size='small' /> &nbsp; &nbsp;

              <p>126/A, Raja Rammohan Sarani, College Street, Kolkata-700009, West Bengal, India</p>
            </div>
            <div class="information">
              <Icon className='fas' name='mail' size='small' /> &nbsp; &nbsp;
              <p>info@creativeicon.co.in</p>
            </div>
            <div class="information">
              <Icon className='fas' name='phone' size='small' />&nbsp;&nbsp;
              <p>+91 9836492808</p>
            </div>
          </div>

          <div class="social-media">
            <p>Connect with us :</p>
            <div class="social-icons">
              <a href="#">
                <Icon className='fas' name='facebook' size='small' />
              </a>
              <a href="#">
                <Icon className='fas' name='twitter' size='small' />
              </a>
              <a href="#">
                <Icon className='fas' name='instagram' size='small' />
              </a>
              <a href="#">
                <Icon className='fas' name='youtube' size='small' />
              </a>
            </div>
          </div>
        </div>

        <div class="contact-form">
          <span class="circle one"></span>
          <span class="circle two"></span>
          <div className='form-div'>
            <h3 class="title">Contact us</h3>
            <FormComponent
                            onSubmit={onSubmit}
                            saveText={'Send message'}
                            form='contact'
                            initialValues={{}}
                            fieldList={[
                                {
                                    type: 'text',
                                    key: 'name',
                                    name: 'Your name',
                                    required: true,
                                    size: '6'
                                },
                                {
                                    type: 'text',
                                    key: 'email',
                                    name: 'Your email',
                                    required: true,
                                    size: '6'
                                },
                                {
                                    type: 'textarea',
                                    key: 'message',
                                    name: 'Your message',
                                    required: true,
                                    size: '12'
                                }
                            ]}
                        />
                      </div>
        </div>
      </div>
    </div>
        <MapContainer style={{height: '400px'}} center={[22.572645, 88.363892]} zoom={10} scrollWheelZoom={false}>
                            <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {
                                positionArray.map(pos=>(
                                    <Marker position={pos.position} key={pos.office}>
                                        <Popup keepInView>
                                            {pos.office}
                                        </Popup>
                                    </Marker>
                                ))
                            }
                            
                        </MapContainer>
        </>
    )
}