import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './styles/section2.css';

export const Section2 = ({children}) => {
    return (
        <div className='section-2'>
            <Container>
                <Row>
                    <Col md={6} />
                    <Col md={6}>
                        {children}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}