import { put } from "redux-saga/effects";
import { blogResponseRecieved, loadBlogs } from "../actions";
import AxiosInstance from "../AxisoInstance";

export function* saveBlog({payload: {id, data}}) {
    try {
        if(id) {
            yield updateBlog(id, data);
        }
        else {
            yield createBlog(data);
        }
        yield put(blogResponseRecieved({type:'SUCCESS'}));
        yield put(loadBlogs());
    } catch(e) {
        console.error(e);
        yield put(blogResponseRecieved({type:'ERROR'}));
    }
}

const updateBlog = async(id, data) => {
    const response = (await AxiosInstance.putForm(`blogs/${id}`, data));
    return response.data;
}

const createBlog = async(data) => {
    const response = (await AxiosInstance.postForm(`blogs/`, data));
    return response.data;
}

export function* deleteBlog({payload}) {
    try {
        yield deleteItem(payload);
        yield put(blogResponseRecieved({type:'SUCCESS'}));
        yield put(loadBlogs());
    } catch(e) {
        console.error(e);
        yield put(blogResponseRecieved({type:'ERROR'}));
    }
}

const deleteItem = async(id) => {
    const response = (await AxiosInstance.delete(`blogs/${id}`));
    return response.data;
}