import * as React from 'react';
import DocumentTitle from 'react-document-title';

export const Body = ({title, children}) => {
    return (
        <DocumentTitle title={title}>
            <div>
                {children}
            </div>
        </DocumentTitle>
    )
}