import { put } from "redux-saga/effects";
import { contactResponseRecieved } from "../actions";
import AxiosInstance from "../AxisoInstance";

export function* sendContact({payload}) {
    try {
        yield send(payload);
        yield put(contactResponseRecieved({'type':'SUCCESS'}));
    } catch(e) {
        console.error(e);
        yield put(contactResponseRecieved({'type':'ERROR'}));
    }
}

const send = async(payload) => {
    const response = (await AxiosInstance.post(`contact/`, payload));
    return response.data;
}