import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';

import './styles/form.css';
import { FileInput } from './fileInput';

const FormComp = ({fieldList=[], handleSubmit, form, saveText}) => {
    
    return (
        <Form name={form} onSubmit={handleSubmit}>
            <Row>
            {
                fieldList.map(field=> {
                    switch(field.type) {
                        case 'number':
                            return <Col xs={field.size}><Field 
                                component={"input"} 
                                type="number" 
                                id={field.key} 
                                name={field.key}
                                placeholder={field.name} 
                                required={field.required}
                                className='field'
                            /></Col>;
                        case 'select':
                            return <Col xs={field.size}>
                                <label>{field.name}</label><Field 
                                component={"select"} 
                                type="number" 
                                id={field.key} 
                                name={field.key}
                                className='field'
                                placeholder={field.name} 
                                required={field.required}
                            >
                                <option></option>
                                {
                                    (field.values||[]).map(val=>(
                                        <option key={val.value} value={val.value}>{val.label}</option>
                                    ))
                                }
                            </Field></Col>;
                        case 'textarea':
                            return <Col xs={field.size}><Field 
                                component={"textarea"} 
                                rows={4}
                                id={field.key} 
                                className='field'
                                name={field.key}
                                placeholder={field.name} 
                                required={field.required}
                            /></Col>;
                        case 'file':
                            return <Col xs={field.size}>
                            <Field 
                                component={FileInput} 
                                label={field.name}
                                id={field.key} 
                                name={field.key}
                                className='field'
                                placeholder={field.name} 
                                required={field.required}
                            />
                            </Col>;
                        default:
                            return <Col xs={field.size}>
                            <Field 
                                component={"input"} 
                                type={field.input||'text'} 
                                id={field.key} 
                                name={field.key}
                                className='field'
                                placeholder={field.name} 
                                required={field.required}
                            />
                            </Col>;

                    }
                })
            }
            </Row>
            <Button className='submit-button' variant={"primary"} type="submit">{saveText||'Submit'}</Button>
        </Form>
    );
}

export const FormComponent = connect(
    (state, props) => {
        return {
            initialValues: props.initialValues,
            }
    },
    null
)(reduxForm({
    enableReinitialize: true,
  })(FormComp));