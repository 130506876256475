import * as React from 'react';
import { Container, FormSelect } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { categoryResponseRecieved, deleteCategory, loadCategory, saveCategory } from '../../actions';
import { getCategories, getCategoryResponse } from '../../selectors';
import { FormComponent } from '../utils/form';
import { reset } from 'redux-form';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

export const CategoryForm = () => {
    const dispatch = useDispatch();
    const categories = useSelector(getCategories);
    const response = useSelector(getCategoryResponse);
    React.useEffect(()=>{
        if(response) {
            toast(response.type==='SUCCESS' ? 'Saved':'Error Occured');
            if(response.type==='SUCCESS') {
                setSelected('new');
                setCategory({
                    name: '',
                    description: ''
                });
                dispatch(reset('category'));
                
            }
            dispatch(categoryResponseRecieved(undefined));
        }
    },[response])
    const [selected, setSelected] = React.useState('new');
    const [category, setCategory] = React.useState({
        name: '',
        description: ''
    });
    React.useEffect(()=>{
        dispatch(loadCategory());
        setSelected('new');
        setCategory({
            name: '',
            description: ''
        });
    },[])
    const onChange = (event) => {
        setSelected(event.target.value);
        
        if(event.target.value==='new') {
            setCategory({
                name: '',
                description: ''
            });
        }
        else {
            const cat = categories.find(a=>a._id===event.target.value);
            setCategory({
                name: cat?.name,
                description: cat?.description,
                id: cat?._id
            });
        }
        dispatch(reset('category'));
    }
    const onSubmit= (data) => {
        if(selected==='new') {
            dispatch(saveCategory({data}))
        }
        else {
            dispatch(saveCategory({data, id: data.id }))
        }
    }
    const onDelete = () => {
        dispatch(deleteCategory(selected))
    }
    return (
        <Container>
            <FormSelect className='mb-5' value={selected} onChange={onChange} size='lg' label="Select Category">
                <option value={'new'}>Create New</option>
                {
                    categories.map(p=>(
                        <option value={p._id}>{p.name}</option>
                    ))
                }
            </FormSelect>
            {
                    selected &&
                    <>
                    {
                        selected!=='new' &&
                        <Button onClick={onDelete} color='red' size='big' className='mb-3'>Delete Product</Button>
                    }
                    <FormComponent
                        onSubmit={onSubmit}
                        saveText={selected==='new'?'Create Category':'Save Category'}
                        form='category'
                        initialValues={category}
                        fieldList={[
                            {
                                type: 'text',
                                key: 'name',
                                name: 'Category Name',
                                required: true,
                                size: '6'
                            },
                            {
                                type: 'textarea',
                                key: 'description',
                                name: 'Category Description',
                                required: false,
                                size: '12'
                            },
                        ]}
                    />
                    </>
                }
        </Container>
    )
}