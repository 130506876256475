import { put } from "redux-saga/effects";
import { categoryResponseRecieved, loadCategory } from "../actions";
import AxiosInstance from "../AxisoInstance";

export function* saveCategory({payload: {id, data}}) {
    try {
        if(id) {
            yield updateCategory(id, data);
        }
        else {
            yield createCategory(data);
        }
        yield put(categoryResponseRecieved({type:'SUCCESS'}));
        yield put(loadCategory());
    } catch(e) {
        console.error(e);
        yield put(categoryResponseRecieved({type:'ERROR'}));
    }
}

const updateCategory = async(id, data) => {
    const response = (await AxiosInstance.put(`categories/${id}`, data));
    return response.data;
}

const createCategory = async(data) => {
    const response = (await AxiosInstance.post(`categories/`, data));
    return response.data;
}

export function* deleteCategory({payload}) {
    try {
        yield deleteItem(payload);
        yield put(categoryResponseRecieved({type:'SUCCESS'}));
        yield put(loadCategory());
    } catch(e) {
        console.error(e);
        yield put(categoryResponseRecieved({type:'ERROR'}));
    }
}

const deleteItem = async(id) => {
    const response = (await AxiosInstance.delete(`categories/${id}`));
    return response.data;
}