import * as React from 'react';

import './styles/slide.css';

export const Slide = ({image}) => {
    return (
        <div className='slide'>
            <img className='slider-image' src={image} />
        </div>
    )
}