import * as React from 'react';
import { Col, Image, Modal, ModalBody, ModalHeader, Row } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';

import './styles/image-grid.css';


export const ProductGrid = ({images=[]}) => {

    const [selectedImage, setSelectedImage] = React.useState('');

    const openImage = (image) => () => {
        setSelectedImage(image);
    }

    return (
        <div className='pt-3 pb-3 image-grid'>
            <Row>
                {
                    images.map(image=>(
                        <Col md={3} xs={12} sm={4} className='p-0'>
                            <div class="card">
                                <div class="cover item-a" style={{backgroundImage: `url(${image?.src})`}}>
                                    <h1>{image?.text}</h1>
                                    <div class="card-back">
                                        <a href="#" onClick={openImage(image?.src)}>Open</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
            <Modal animation show={selectedImage!==''} onHide={openImage('')} size='lg'>
                <ModalHeader closeButton></ModalHeader>
                <ModalBody>
                    <Image src={selectedImage} style={{maxWidth: '100%'}} />
                </ModalBody>
            </Modal>
        </div>
    )
}