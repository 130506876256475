import axios from "axios";

import { Config } from "./config";
import { cookies } from "./cookieConfig";


const AxiosInstance = axios.create({
  baseURL : Config.baseUrl,
  withCredentials: true,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    "Content-Type": "application/json",
    timeout : 1000,
  }, 
  // .. other options
});


AxiosInstance.interceptors.request.use(config => {
  const token = cookies.get('token');
  if(token) {
    config.headers = {
      Authorization: token,
      ...config.headers,
    }
  }
    
    config.params = {
     // add your default ones
     projectId: 'creative-icon-web',
     // spread the request's params
      ...config.params,
    };
    return config;
  });

export default AxiosInstance;