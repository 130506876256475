import { all, takeLatest } from "redux-saga/effects";

import { CONTACT_SEND, DELETE_BLOG, DELETE_CATEGORY, DELETE_PRODUCT, LOAD_BLOGS, LOAD_CATEGORY, LOAD_PRODUCTS, LOG_IN, OPEN_BLOG, SAVE_BLOG, SAVE_CATEGORY, SAVE_PRODUCT } from "./actions";
import { loadProducts } from "./sagas/loadProducts";
import { loadCategories } from "./sagas/loadCategories";
import { deleteCategory, saveCategory } from "./sagas/saveCategory";
import { deleteProduct, saveProduct } from "./sagas/saveProduct";
import { loadBlogs } from "./sagas/loadBlogs";
import { deleteBlog, saveBlog } from "./sagas/saveBlog";
import { openBlog } from "./sagas/openBlog";
import { login } from "./sagas/login";
import { sendContact } from "./sagas/contact";


export function* rootSaga() { 
    yield all([
        takeLatest(LOAD_PRODUCTS, loadProducts),
        takeLatest(LOAD_CATEGORY, loadCategories),
        takeLatest(SAVE_CATEGORY, saveCategory),
        takeLatest(SAVE_PRODUCT, saveProduct),
        takeLatest(LOAD_BLOGS, loadBlogs),
        takeLatest(SAVE_BLOG, saveBlog),
        takeLatest(OPEN_BLOG, openBlog),
        takeLatest(DELETE_BLOG, deleteBlog),
        takeLatest(DELETE_CATEGORY, deleteCategory),
        takeLatest(DELETE_PRODUCT, deleteProduct),
        takeLatest(LOG_IN, login),
        takeLatest(CONTACT_SEND, sendContact),
    ]);
}