import * as React from 'react';

import { Slider } from '../../utils/slider';
import { Section1 } from '../../utils/section1';
import { Col, Image, Row } from 'react-bootstrap';
import { HeaderTopText } from '../../utils/header-top-text';
import { HeaderText } from '../../utils/header-text';
import { Section2 } from '../../utils/section2';
import { Panel1 } from '../../utils/panel1';
import { Icon } from 'semantic-ui-react';
import { Section3 } from '../../utils/section3';
import { ProductFilter } from '../../utils/product-filter';
import { Blogs } from '../../utils/blogs';
import { Contact } from '../../utils/contact';
import { Body } from '../../utils/body';
import { useDispatch, useSelector } from 'react-redux';
import { categoryLoaded, loadCategory } from '../../../actions';
import { getCategories } from '../../../selectors';
import { PerspectiveSection } from '../../utils/perspective-section';
import { CategoryDiv } from '../../utils/categoryDiv';
import { Wave } from '../../utils/wave';
import { Rain } from '../../utils/rain';

export const Home = () => {
    const dispatch = useDispatch();
    const categories = useSelector(getCategories());
    React.useEffect(()=>{
        dispatch(loadCategory());
        return (()=>{
            dispatch(categoryLoaded([]));
        })
    },[])
    return (
        <Body title={'Creative Icon - Home'}>
            <Slider sliders={[...Array(14).keys()].map(a=>`${process.env.PUBLIC_URL}/images/sliders/${(a+1)}.jpg`)} />
            <Wave />
            <PerspectiveSection image={`${process.env.PUBLIC_URL}/images/1.jpeg`}>
                <HeaderTopText color={'#e947a5'} text={'about us'} />
                <p>
                With 20 years of excellence in the graphic design industry, Creative Icon has become a premier destination for innovative and visually captivating design solutions. Our studio is renowned for its ability to merge artistic vision with strategic insight, resulting in designs that not only captivate but also deliver tangible results for our clients.
                </p>
                <p>
                Our extensive experience spans across various domains, including branding, digital media, advertising, and print design. We leverage cutting-edge techniques and stay ahead of industry trends to ensure our designs are both contemporary and impactful. At Creative Icon, our talented team of designers brings a wealth of knowledge and a keen eye for detail, ensuring every project reflects our commitment to quality and creativity.
                </p>
            </PerspectiveSection>
            <CategoryDiv categories={[
                'creative services',
                'Packaging & Labels Services',
                'creative Stationeries',
                'Social Media & Digital Ads Services',
                'Motion Graphic & Video Services',
                'Graphic Design & Illustration Services',
                'Print Advertising Services',
                'Logo & Branding Design Services with trade mark services',
                'Presentation & Publication Services',
                'press ad creative',
                'TVC production',
                'Photography Advertising Agency',
                'Brochure Designing',
                'Brand Identity creation',
                'Flyers design'
            ]} />
            <PerspectiveSection reverse image={`${process.env.PUBLIC_URL}/images/2.jpeg`}>
                <HeaderTopText color={'#e947a5'} text={'What are we'} />
                <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. 
                </p>
            </PerspectiveSection>
            <ProductFilter filters={[]}>
                <HeaderTopText color={'#fff'} text={'recent works'} />
                <br />
                <HeaderText color={'#0e0e12'} text={'Our Projects'} /> 
            </ProductFilter>
            <Contact />
            <Blogs only10>
                <HeaderText color={'#0e0e12'} text={'Fresh blog post'} /> 
            </Blogs>
            
        </Body>
    )
}