
import { put } from "redux-saga/effects";
import { blogsLoaded } from "../actions";
import AxiosInstance from "../AxisoInstance";

export function* loadBlogs() {
    try {
        const blogs = yield getBlogs();
        yield put(blogsLoaded(blogs));
    } catch(e) {
        console.error(e);
        yield put(blogsLoaded([]));
    }
}

const getBlogs = async() => {
    const response = (await AxiosInstance.get(`blogs/`));
    return response.data;
}