import * as React from 'react';

import './styles/perspective-section.css';

export const PerspectiveSection = ({children, image, reverse=false}) => {
    return (
        <div className='perspective-outer'>
        <div class="perspective-section">
            <div class="grid">
                {
                    reverse ?
                    <>
                        <figure class="td-figure">
                        <img src={ image || "https://images.unsplash.com/photo-1603695454344-12df53ab0c11?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ"} alt="Image description" />
                        </figure>
                        <div class="text-component">
                            {children}
                        </div>
                    </>
                    :
                    <>
                        <div class="text-component">
                            {children}
                        </div>
                        <figure class="td-figure">
                        <img src={ image || "https://images.unsplash.com/photo-1603695454344-12df53ab0c11?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ"} alt="Image description" />
                        </figure>
                    </>
                }
                

                
            </div>
        </div>
        </div>
    )
}