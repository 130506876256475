import { path, pathOr } from "ramda";

export const isLoading = pathOr(false, ['root','loading']);

export const getProducts = pathOr([], ['root','products']);

export const getCategories = pathOr([], ['root','product_categories']);

export const getBlogs = pathOr([], ['root','blogs']);

export const getLoginResponse = path(['root','login_response']);

export const getContactResponse = path(['root','contact_response']);

export const getBlog = path(['root','blog']);

export const getBlogResponse = path(['root','blog_response']);

export const getProductResponse = path(['root','product_response']);

export const getCategoryResponse = path(['root','category_response']);