import * as React from 'react';
import { Body } from '../utils/body';
import { SectionHeader } from '../utils/section-header';
import { Blogs } from '../utils/blogs';
import { HeaderText } from '../utils/header-text';

export const BlogsPage = () => {
    return (
        <Body title={'Blogs - Creative Icon'}>
            <SectionHeader section={'Blogs'} />
            <Blogs>
                <HeaderText color={'#0e0e12'} text={'blog post'} /> 
            </Blogs>
        </Body>
    )
}