import * as React from 'react';
import { Body } from '../utils/body';
import { SectionHeader } from '../utils/section-header';
import { Blogs } from '../utils/blogs';
import { Container, Image } from 'react-bootstrap';
import { Section1 } from '../utils/section1';
import { useDispatch, useSelector } from 'react-redux';
import { getBlog } from '../../selectors';
import { openBlog } from '../../actions';
import { Config } from '../../config';
import { HeaderText } from '../utils/header-text';
import { useParams } from 'react-router-dom';

export const BlogSinglePage = (props) => {
    const {blogId} = useParams();
    const dispatch = useDispatch();
    const blog = useSelector(getBlog);
    React.useEffect(()=>{
        dispatch(openBlog(blogId))
    },[blogId])

    if(!blog){
        return null;
    }

    return (
        <Body title={`Blogs - ${blog.title} - Creative Icon`}>
            <SectionHeader section={`Blogs - ${blog.title}`} />
            <Section1>
                <Image src={`${Config.baseImageUrl}/${blog.image.split('/').join(',')}`} style={{width: '100%'}} />
                <HeaderText color={'#000'} text={blog.title} />
                <br />
                <p>{blog.description}</p>
                <br />
                <div style={{textAlign: 'right'}}>
                    <span>- <i>by {blog.writtenBy}</i></span>
                </div>
            </Section1>
        </Body>
    )
}