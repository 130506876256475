import { createReducer } from "@reduxjs/toolkit";
import { blogOpened, blogResponseRecieved, blogsLoaded, categoryLoaded, categoryResponseRecieved, contactResponseRecieved, contactSend, deleteBlog, deleteCategory, deleteProduct, loadBlogs, loadCategory, loadProducts, login, loginResponseRecieved, openBlog, productResponseRecieved, productsLoaded, saveBlog, saveCategory, saveProduct } from "./actions";

const INITIAL_STATE = {
    loading: false,
    products: [],
    product_categories: [],
    blogs: [],
    product_response: undefined,
    category_response: undefined,
    login_response: undefined,
    blog_response: undefined,
    contact_response: undefined,
    blog: undefined,
}

export const reducers = createReducer(INITIAL_STATE, (builder) =>  {
    builder.addCase(loadProducts, (state, _action) => {
        state.loading = true;
        state.products = [];
    }).addCase(productsLoaded, (state, action) => {
        state.loading = false;
        state.products = action.payload;
    }).addCase(saveProduct, (state, _action) => {
        state.loading = true;
        state.product_response = undefined;
    }).addCase(deleteProduct, (state, _action) => {
        state.loading = true;
        state.product_response = undefined;
    }).addCase(productResponseRecieved, (state, action) => {
        state.loading = false;
        state.product_response = action.payload;
    })
    .addCase(loadCategory, (state, _action) => {
        state.loading = true;
        state.product_categories = [];
    }).addCase(categoryLoaded, (state, action) => {
        state.loading = false;
        state.product_categories = action.payload;
    }).addCase(saveCategory, (state, _action) => {
        state.loading = true;
        state.category_response = undefined;
    }).addCase(deleteCategory, (state, _action) => {
        state.loading = true;
        state.category_response = undefined;
    }).addCase(categoryResponseRecieved, (state, action) => {
        state.loading = false;
        state.category_response = action.payload;
    })
    .addCase(loadBlogs, (state, _action) => {
        state.loading = true;
        state.blogs = [];
    }).addCase(blogsLoaded, (state, action) => {
        state.loading = false;
        state.blogs = action.payload;
    }).addCase(saveBlog, (state, _action) => {
        state.loading = true;
        state.blog_response = undefined;
    }).addCase(deleteBlog, (state, _action) => {
        state.loading = true;
        state.blog_response = undefined;
    }).addCase(blogResponseRecieved, (state, action) => {
        state.loading = false;
        state.blog_response = action.payload;
    }).addCase(openBlog, (state, _action) => {
        state.loading = true;
        state.blog = undefined;
    }).addCase(blogOpened, (state, action) => {
        state.loading = false;
        state.blog = action.payload;
    })
    .addCase(login, (state, _action) => {
        state.loading = true;
        state.login_response = undefined;
    }).addCase(loginResponseRecieved, (state, action) => {
        state.loading = false;
        state.login_response = action.payload;
    })
    .addCase(contactSend, (state, _action) => {
        state.loading = true;
        state.contact_response = undefined;
    }).addCase(contactResponseRecieved, (state, action) => {
        state.loading = false;
        state.contact_response = action.payload;
    })
})