import * as React from 'react';
import { Body } from '../utils/body';
import { SectionHeader } from '../utils/section-header';
import { Panel1 } from '../utils/panel1';
import { Col, Image, Row } from 'react-bootstrap';
import { HeaderTopText } from '../utils/header-top-text';
import { HeaderText } from '../utils/header-text';
import { Section2 } from '../utils/section2';
import { Section1 } from '../utils/section1';
import { PerspectiveSection } from '../utils/perspective-section';

export const About = () => {
    return (
        <Body title={'About Us - Creative Icon'}>
            <SectionHeader section={'About Us'} />
            <PerspectiveSection>
                <HeaderTopText color={'#e947a5'} text={'about us'} />
                <p>
                With 20 years of excellence in the graphic design industry, Creative Icon has become a premier destination for innovative and visually captivating design solutions. Our studio is renowned for its ability to merge artistic vision with strategic insight, resulting in designs that not only captivate but also deliver tangible results for our clients.
                </p>
                <p>
                Our extensive experience spans across various domains, including branding, digital media, advertising, and print design. We leverage cutting-edge techniques and stay ahead of industry trends to ensure our designs are both contemporary and impactful. At Creative Icon, our talented team of designers brings a wealth of knowledge and a keen eye for detail, ensuring every project reflects our commitment to quality and creativity.
                </p>
            </PerspectiveSection>
        </Body>
    )
}