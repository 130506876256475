
import { put } from "redux-saga/effects";
import { categoryLoaded } from "../actions";
import AxiosInstance from "../AxisoInstance";

export function* loadCategories() {
    try {
        const categories = yield getCategories();
        yield put(categoryLoaded(categories));
    } catch(e) {
        console.error(e);
        yield put(categoryLoaded([]));
    }
}

const getCategories = async() => {
    const response = (await AxiosInstance.get(`categories/`));
    return response.data;
}