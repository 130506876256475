import * as React from 'react';

import './styles/categoryDiv.css';

export const CategoryDiv = ({categories=[]}) => {
    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    return (
        <div class="text-container">
            {
                categories.map((a,i)=>(
                    <div style={{animationDelay:`${(i)*2}s`, left: `${getRandomInt(10,91)}%`, top: `${getRandomInt(10,71)}%`}} className={`child${i%5+1}`}>{a}</div>
                ))
            }
        </div>
    )
}