import * as React from 'react';

import './styles/loading.css';
import { Image } from 'react-bootstrap';

export const Loading = () => {
    return (
        <div className='loading'>
            <Image style={{width: '100%', height: 'auto'}} src={`${process.env.PUBLIC_URL}/images/loader.gif`} />
        </div>
    )
}