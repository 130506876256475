import 'react-bootstrap/helpers';
import 'semantic-ui-css/semantic.min.css';
import 'react-bootstrap/helpers';
import { Route, BrowserRouter as Router, Routes  } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import { Header } from './features/utils/header';
import { Home } from './features/home/components/home';
import { Footer } from './features/utils/footer';
import { Admin } from './features/admin/admin';
import { ContactPage } from './features/contact/contact';
import { About } from './features/about/about';
import { BlogsPage } from './features/blog/blog';
import { ToastContainer } from 'react-toastify';
import { BlogSinglePage } from './features/blog/blog-single';
import { Loading } from './features/utils/loading';
import { useSelector } from 'react-redux';
import { isLoading } from './selectors';
import { Wave } from './features/utils/wave';

function App() {
  const loading = useSelector(isLoading);
  return (
    <Router>
      <Header />
      <div style={{paddingTop: '6rem'}}></div>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogs/:blogId" element={<BlogSinglePage />} />
      </Routes>
      <div style={{width: '100%', height: '2rem', background: '#151414'}}></div>
      <Wave />
      <Footer />
      <ToastContainer />
      {
        loading &&
        <Loading />
      }
    </Router>
  );
}

export default App;
