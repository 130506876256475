import { createAction } from "@reduxjs/toolkit";

export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const PRODUCTS_LOADED = "PRODUCTS_LOADED";

export const LOG_IN = "LOG_IN";
export const LOG_IN_RESPONSE_RECIEVED = "LOG_IN_RESPONSE_RECIEVED";

export const SAVE_PRODUCT = "SAVE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const PRODUCT_RESPONSE_RECIEVED = "PRODUCT_RESPONSE_RECIEVED";

export const LOAD_CATEGORY = "LOAD_CATEGORY";
export const CATEGORY_LOADED = "CATEGORY_LOADED";

export const SAVE_CATEGORY = "SAVE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const CATEGORY_RESPONSE_RECIEVED = "CATEGORY_RESPONSE_RECIEVED";

export const LOAD_BLOGS = "LOAD_BLOGS";
export const BLOGS_LOADED  = "BLOGS_LOADED";
export const OPEN_BLOG  = "OPEN_BLOG";
export const BLOG_OPENED  = "BLOG_OPENED";

export const SAVE_BLOG = "SAVE_BLOG";
export const DELETE_BLOG = "DELETE_BLOG";
export const BLOG_RESPONSE_RECIEVED = "BLOG_RESPONSE_RECIEVED";

export const CONTACT_SEND = "CONTACT_SEND";
export const CONTACT_RESPONSE_RECIEVED = "CONTACT_RESPONSE_RECIEVED";

export const loadProducts = createAction(LOAD_PRODUCTS);
export const productsLoaded = createAction(PRODUCTS_LOADED);
export const saveProduct = createAction(SAVE_PRODUCT);
export const deleteProduct = createAction(DELETE_PRODUCT);
export const productResponseRecieved = createAction(PRODUCT_RESPONSE_RECIEVED);

export const loadCategory = createAction(LOAD_CATEGORY);
export const categoryLoaded = createAction(CATEGORY_LOADED);
export const saveCategory = createAction(SAVE_CATEGORY);
export const deleteCategory = createAction(DELETE_CATEGORY);
export const categoryResponseRecieved = createAction(CATEGORY_RESPONSE_RECIEVED);

export const loadBlogs = createAction(LOAD_BLOGS);
export const blogsLoaded = createAction(BLOGS_LOADED);
export const openBlog = createAction(OPEN_BLOG);
export const blogOpened = createAction(BLOG_OPENED);
export const saveBlog = createAction(SAVE_BLOG);
export const deleteBlog = createAction(DELETE_BLOG);
export const blogResponseRecieved = createAction(BLOG_RESPONSE_RECIEVED);

export const contactSend = createAction(CONTACT_SEND);
export const contactResponseRecieved = createAction(CONTACT_RESPONSE_RECIEVED);

export const login = createAction(LOG_IN);
export const loginResponseRecieved = createAction(LOG_IN_RESPONSE_RECIEVED);
