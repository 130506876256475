import * as React from 'react';
import {  Container, FormSelect, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { blogResponseRecieved, deleteBlog,loadBlogs, saveBlog } from '../../actions';
import { getBlogResponse, getBlogs } from '../../selectors';
import { FormComponent } from '../utils/form';
import { reset } from 'redux-form';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

export const BlogForm = () => {
    const dispatch = useDispatch();
    const blogs = useSelector(getBlogs);
    const response = useSelector(getBlogResponse);
    React.useEffect(()=>{
        if(response) {
            toast(response.type==='SUCCESS' ? 'Saved':'Error Occured');
            if(response.type==='SUCCESS') {
                setSelected('new');
                dispatch(reset('blog'));
            }
            dispatch(blogResponseRecieved(undefined));
        }
    },[response])
    const [selected, setSelected] = React.useState('new');
    const [blog, setBlog] = React.useState({
        title: '',
        description: '',
        image: null,
        writtenBy: ''
    });
    React.useEffect(()=>{
        dispatch(loadBlogs());
        setSelected('new');
    },[])
    const onChange = (event) => {
        setSelected(event.target.value);
        dispatch(reset('blog'));
        if(event.target.value==='new') {
            setBlog({
                title: '',
                description: '',
                image: null,
                writtenBy: ''
            });
        }
        else {
            const blg = blogs.find(a=>a._id===event.target.value);
            setBlog({
                title: blg?.name,
                description: blg?.description,
                image: blg?.image,
                writtenBy: blg?.cwrittenBy,
                id: blg?._id
            });
        }
    }
    const onSubmit= (data) => {
        console.log(data);
        if(selected==='new') {
            const formData = new FormData();
            formData.append('title', data.title);
            formData.append('description', data.description);
            formData.append('image', data.image);
            formData.append('writtenBy', data.writtenBy);
            dispatch(saveBlog({data: formData}))
        }
        else {
            const formData = new FormData();
            formData.append('title', data.title);
            formData.append('description', data.description);
            if(data.image instanceof File)
                formData.append('image', data.image);
            formData.append('writtenBy', data.writtenBy);
            dispatch(saveBlog({data: formData, id: data.id }))
        }
    }
    const onDelete = () => {
        dispatch(deleteBlog(selected))
    }
    return (
        <Container>
            <FormSelect className='mb-5' value={selected} onChange={onChange} size='lg' label="Select Blog">
                <option value={'new'}>Create New</option>
                {
                    blogs.map(p=>(
                        <option value={p._id}>{p.name}</option>
                    ))
                }
            </FormSelect>
            {
                    selected &&
                    <>
                    {
                        selected!=='new' &&
                        <Button onClick={onDelete} color='red' size='big' className='mb-3'>Delete Blog</Button>
                    }
                    <FormComponent
                        onSubmit={onSubmit}
                        saveText={selected==='new'?'Create Blog':'Save Blog'}
                        form='blog'
                        initialValues={blog}
                        fieldList={[
                            {
                                type: 'text',
                                key: 'title',
                                name: 'Title',
                                required: true,
                                size: '6'
                            },
                            {
                                type: 'text',
                                key: 'writtenBy',
                                name: 'Written By',
                                required: true,
                                size: '6'
                            },
                            {
                                type: 'textarea',
                                key: 'description',
                                name: 'Description',
                                required: true,
                                size: '12'
                            },
                            {
                                type: 'file',
                                key: 'image',
                                name: 'Image',
                                required: selected==='new',
                                size: '12'
                            },
                        ]}
                    />
                    </>
                }
        </Container>
    )
}