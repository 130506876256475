import { put } from "redux-saga/effects";
import { productsLoaded } from "../actions";
import AxiosInstance from "../AxisoInstance";

export function* loadProducts() {
    try {
        const products = yield getProducts();
        yield put(productsLoaded(products));
    } catch(e) {
        console.error(e);
        yield put(productsLoaded([]));
    }
}

const getProducts = async() => {
    const response = (await AxiosInstance.get(`products/`));
    return response.data;
}