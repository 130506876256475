
import { put } from "redux-saga/effects";
import { loginResponseRecieved } from "../actions";
import AxiosInstance from "../AxisoInstance";
import { cookies } from "../cookieConfig";



export function* login({payload}) {
    try {
        const user = yield send(payload);
        yield cookies.set('token', user.token);
        yield put(loginResponseRecieved({'type':'SUCCESS'}));
    } catch(e) {
        console.error(e);
        yield put(loginResponseRecieved({'type':'ERROR'}));
    }
}

const send = async(payload) => {
    const response = (await AxiosInstance.post(`auth/login/`, payload));
    return response.data;
}