import * as React from 'react';
import { Body } from '../utils/body';
import { SectionHeader } from '../utils/section-header';
import { cookies } from '../../cookieConfig';
import { Section2 } from '../utils/section2';
import { HeaderTopText } from '../utils/header-top-text';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { FormComponent } from '../utils/form';
import { useDispatch, useSelector } from 'react-redux';
import { login, loginResponseRecieved } from '../../actions';
import { ProductForm } from './productForm';
import { CategoryForm } from './categoryForm';
import { BlogForm } from './blogForm';
import { getLoginResponse } from '../../selectors';
import { toast } from 'react-toastify';

export const Admin = () => {
    const dispatch = useDispatch();
    const response =  useSelector(getLoginResponse);
    React.useEffect(()=>{
        if(response) {
            toast(response.type==='SUCCESS' ? 'Log in Successfull':'Incorrect Email or Password!!');
            if(response.type==='SUCCESS') {
                window.location.reload();
            }
            dispatch(loginResponseRecieved(undefined));
        }
    },[response, dispatch])
    React.useEffect(()=>{
        return (()=>{
            dispatch(loginResponseRecieved(undefined));
        })
    },[dispatch])
    const onSubmit = (payload) => {
        dispatch(login(payload));
    }
    return (
        <Body title={'Admin Panel - Creative Icon'}>
            <SectionHeader section={'Admin'} />
            {
                cookies.get('token') ? 
                <div style={{background: '#1a1a1a'}}>
                    <Container>
                    <Tabs
                        defaultActiveKey="category"
                        className="mb-3"
                    >
                        <Tab eventKey="products" title="Products">
                            <ProductForm />
                        </Tab>
                        <Tab eventKey="category" title="Category">
                            <CategoryForm />
                        </Tab>
                        <Tab eventKey="blogs" title="Blogs">
                            <BlogForm />
                        </Tab>
                    </Tabs>
                    </Container>
                </div>:
                <div>
                    <Section2>
                        <HeaderTopText color={'#fcd63c'} text={'Log In'} />
                        <br />
                        <Container>
                            <FormComponent 
                                onSubmit={onSubmit}
                                saveText={'Log In'}
                                form='login'
                                initialValues={{}}
                                fieldList={[
                                    {
                                        type: 'text',
                                        key: 'email',
                                        input: 'email',
                                        name: 'Enter email',
                                        required: true,
                                        size: '12'
                                    },
                                    {
                                        type: 'text',
                                        key: 'password',
                                        input: 'password',
                                        name: 'Your Password',
                                        required: true,
                                        size: '12'
                                    },
                                ]}
                            />
                        </Container>
                    </Section2>
                </div>
            }
        </Body>
    )
}