import * as React from 'react';
import { Image } from 'react-bootstrap';
import { Config } from '../../config';

export const FileInput = ({input, label}) => {
    const onChangefield = (e) => {
        const file = e.target.files[0];
        if(file) {
            input.onChange(file);
        }
            
      }
    return (
        <>
            <label>{label}</label>
            <input type='file' className='field' name={input.name} onChange={onChangefield} />
            {
                (typeof input.value==='string' && input.value )&&
                <Image className='mb-3' src={`${Config.baseImageUrl}/${input.value.split('/').join(',')}`} width={100} />
            }
            
        </>
    )
}