import * as React from 'react';
import { Container } from 'react-bootstrap';

import './styles/section1.css';

export const Section1 = ({children}) => {
    return (
        <div className='section-1'>
            <Container>
                {children}
            </Container>
        </div>
    )
}