import { put } from "redux-saga/effects";
import { loadProducts, productResponseRecieved } from "../actions";
import AxiosInstance from "../AxisoInstance";

export function* saveProduct({payload: {id, data}}) {
    try {
        if(id) {
            yield updateProduct(id, data);
        }
        else {
            yield createProduct(data);
        }
        yield put(productResponseRecieved({type:'SUCCESS'}));
        yield put(loadProducts());
    } catch(e) {
        console.error(e);
        yield put(productResponseRecieved({type:'ERROR'}));
    }
}

const updateProduct = async(id, data) => {
    const response = (await AxiosInstance.putForm(`products/${id}`, data));
    return response.data;
}

const createProduct = async(data) => {
    const response = (await AxiosInstance.postForm(`products/`, data));
    return response.data;
}

export function* deleteProduct({payload}) {
    try {
        yield deleteItem(payload);
        yield put(productResponseRecieved({type:'SUCCESS'}));
        yield put(loadProducts());
    } catch(e) {
        console.error(e);
        yield put(productResponseRecieved({type:'ERROR'}));
    }
}

const deleteItem = async(id) => {
    const response = (await AxiosInstance.delete(`products/${id}`));
    return response.data;
}